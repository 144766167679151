@font-face {
  font-family: 'Omnes-regular';
  src: url(/assets/fonts/OmnesSemiCondRegular.ttf) format('truetype');
}

@font-face {
  font-family: 'Omnes-semibold';
  src: url(/assets/fonts/Omnes\ SemiCond\ SemiBold.ttf) format('truetype');
}

@font-face {
  font-family: 'Omnes-bold';
  src: url(/assets/fonts/Omnes\ SemiCond\ Bold.ttf) format('truetype');
}

@font-face {
  font-family: 'Omnes-medium';
  src: url(/assets/fonts/Omnes\ SemiCond\ Medium.ttf) format('truetype');
}

:root {
  /* COLORES */
  --p-blue: #009bdc;
  --p-blue-dark: #111e3a;
  --p-gray: #8c8c8c;
  --se-white-gray: #f0f0f2;
  --se-gray-white: #dbdbdc;
  --se-yellow: #f9c100;
  --st-green: #4df1a1;
  --st-blue: #009bdc;
  --st-red: #e02020;
  --st-orange: #ff9800;
  --p-white: white;
  --font-color: #0F113E;
  /* LOOK AND FEEL COLORS */
  --bs-primary: #FDC224;
  --bs-secondary: #10AEEF;
  --bs-background: #0F113E;
  --bs-cyan: #81daff;
  --bs-grey-background: #5f5f5f;
  --bs-alert-warning: #FCC6D6;
  --bs-alert-info: #FFEFC4;
  --bs-alert-success: #E2FFC4;
  --bs-border-light-gray: #5A5A5A24;
  --bs-background-light-gray: #DBDBDB66;
  /* FUENTES */
  --font-h1: bold 32px "Omnes-bold", "Helvetica Neue", sans-serif;
  --font-h2: bold 26px "Omnes-bold", "Helvetica Neue", sans-serif;
  --font-h3: bold 24px "Omnes-bold", "Helvetica Neue", sans-serif;
  --font-h4: bold 20px "Omnes-bold", "Helvetica Neue", sans-serif;
  --font-h5: bold 18px "Omnes-bold", "Helvetica Neue", sans-serif;
  --font-pf-16: bold 12px "Omnes-bold", "Helvetica Neue", sans-serif;
  --font-p-20: 14px "Omnes-regular", "Helvetica Neue", sans-serif;
  --font-p-16: 12px "Omnes-regular", "Helvetica Neue", sans-serif;
  --font-line-height: 18px;
  --font-semi: regular "Omnes-semibold", "Helvetica Neue", sans-serif;

  /* MARGENES */
  --margin-30: 30px;
  --margin-24: 24px;
  --margin-20: 20px;
  --margin-18: 18px;
  --margin: 0 0 0 0;
  --margin-cont: 30px 16px;

  /* ANCHOS */
  --min-width-dsk: 1000px;
  --min-width-tbt: 716px;
  --min-width-mbl: 335px;
}

/* Cambiar el color del fondo del backdrop del modal */
.custom-backdrop {
  background-color: rgba(0, 24, 57, 0.5);
  opacity: 1 !important;
  backdrop-filter: blur(5px);
  -webkit-backdrop-filter: blur(5px);
}

/* Cambiar el color del fondo del modal */
.custom-modal .modal-content {
  background: linear-gradient(180deg, rgba(0, 64, 95, 0.1) 0%, rgba(15, 17, 62, 0.2) 100%) !important;
  border: 0;
  backdrop-filter: blur(8px) !important;
  -webkit-backdrop-filter: blur(8px) !important;
  max-width: 420px;
  width: 100%;
}

.custom-modal-loading .modal-content {
  background: linear-gradient(180deg, rgba(0, 64, 95, 0.1) 0%, rgba(15, 17, 62, 0.2) 100%) !important;
  border: 0;
  backdrop-filter: blur(8px) !important;
  -webkit-backdrop-filter: blur(8px) !important;
  max-width: 720px;
  width: 100%;
}

html,
body {
  font: 16px "Omnes-regular", "Helvetica Neue", sans-serif;
  height: 100%;
}

p {
  font: var(--font-p-20);
  color: var(--font-color);
  margin: var(--margin);
  margin-bottom: var(--margin-18);
  line-height: var(--font-line-height);
}

.contenido {
  margin: var(--margin-cont);
  max-width: 950px;
}

h1 {
  font: var(--font-h1);
  color: var(--font-color);
  margin: var(--margin);
  /* margin-bottom: var(--margin-30); */
  /* text-transform: lowercase; */
}

h2 {
  font: var(--font-h2);
  color: var(--font-color);
  margin: var(--margin);
  text-transform: lowercase;
}

h3 {
  font: var(--font-h3);
  color: var(--font-color);
  margin: var(--margin);
  text-transform: lowercase;
}

h4 {
  font: var(--font-h4);
  color: var(--font-color);
  margin: var(--margin);
  text-transform: lowercase;
}

h5 {
  font: var(--font-h5);
  color: var(--font-color);
  margin: var(--margin);
  text-transform: lowercase;
}

h1::first-letter,
h2::first-letter,
h3::first-letter,
h4::first-letter,
h5::first-letter,
p::first-letter {
  text-transform: capitalize;
}

.fw-semibold {
  font: var(--font-semi);
}

textarea {
  resize: none;
  width: 100%;
  max-width: 621px;
  height: 414px;
  border-radius: 10px;
  border: 3px solid #181d40;
  padding: 20px 8px;
  outline: none;
  font-size: var(--font-p-16);
  line-height: var(--font-line-height);
  margin-bottom: var(--margin-30);
  text-align: justify;
}

.wrapper {
  width: 300px;
  margin-left: auto;
  margin-right: auto;
  margin-top: 20vh;
  text-align: center;
  font-size: 1.5rem;
}

textarea::-webkit-scrollbar {
  width: 8px;
  background-color: white;
  cursor: pointer;
}

textarea::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background-color: #181d40;
}

.m-bot {
  display: none !important;
}

.col-sm {
  flex-basis: 0;
  flex-grow: 1;
  max-width: 100%;
  padding: 0px !important;
  color: white;
}

.row {
  margin: 0px !important;
}

.caja-r .header-caja {
  padding-top: 32px;
  margin-bottom: 15px;
  font-size: 16px;
  font-weight: 400;
  max-width: 655px;
}

.caja-r {
  width: 50%;
}

.logo {
  width: 20%;
}

.logo-responsive {
  display: none;
}

.inicio-responsive {
  display: none;
}

.caja-l .texto {
  margin: 72px 40px;
  max-width: 760px;
}

.caja-l .texto div {
  font-size: 34px;
  font-weight: bold;
  line-height: 25px;
  display: inline-block;
  background-color: var(--primary-dark);
  color: var(--primary-white);
  width: auto;
  padding: 8px 16px;
}

.inicio-sesion {
  display: flex;
  justify-content: flex-end;
}

.inicio-sesion-contenido {
  display: flex;
  align-items: center;
}

.inicio-sesion span {
  color: #009ee6;
  font-size: 39px !important;
}

.mat-form-field {
  display: inline-block;
  position: relative;
  text-align: left;
  height: 50px !important;
  margin-bottom: 32px;
}

.btn-primary {
  color: var(--p-white);
  background-color: var(--p-blue);
  width: 305px;
  height: 60px;
  border: none;
  font: var(--font-p-20);
}

.cont-form {
  margin-left: 32px;
  max-width: 780px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

form {
  margin-top: 40px;
}

-container::-webkit-scrollbar {
  display: none !important;
}

.continuar {
  background-color: var(--p-blue);
  width: 300px;
  height: 60px;
  color: var(--p-white);
  outline: none;
  height: 50px;
  font: var(--font-p-20);
  border: none;
  border-radius: 5px;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.mat-filter {
  margin-top: -20px;
  margin-bottom: 20px;
}

.mat-filter-input {
  border: 0px solid black !important;
}

.no-padding .mat-dialog-container {
  height: 100%;
  max-height: 763px;
  overflow-y: hidden;
}

.atlwdg-trigger.atlwdg-SUBTLE {
  z-index: 2;
}

.img-main {
  position: fixed !important;
  z-index: 1000px !important;
  margin-left: 4% !important;
  margin-top: -10px !important;
}

.incidentes {
  position: fixed !important;
  z-index: 999 !important;
  bottom: 0 !important;
  left: 0 !important;
  max-width: 235px;
  width: 15vw;
}

.incidentes div {
  position: relative;
  background: #0f113e !important;
  max-width: 235px;
  width: 15vw;
  font-size: 12px;
}

.incidentes div .complemento {
  position: absolute;
  width: 69% !important;
  height: min-content !important;
  padding-left: 2%;
  /* left: -20px; */
  bottom: 50%;
  transform: translate(0, 50%);
  border-left: 1px solid var(--bs-cyan);
  /* font-size: 10px; */
}

.incidentes div .complemento p {
  font-size: 10px !important;
  width: fit-content !important;
  height: min-content !important;
  margin: 0 !important;
  color: var(--bs-cyan) !important;
  /* left: 20px; */
}

#atlwdg-trigger {
  position: fixed;
  z-index: 1000 !important;
  left: 45%;
  bottom: 0;
  width: min-content !important;
  background: #0f113e;
  color: var(--bs-cyan) !important;
  border: 1px solid var(--bs-cyan);
  border-radius: 50px;
  padding: 2px 18px;
  font-size: 12px;
  transform: translate(-93%, -95%);
}

#hubspot-messages-iframe-container {
  position: fixed !important;
  bottom: 0 !important;
  right: 0 !important;
  left: auto !important;
  z-index: 999 !important;
}

.download-spinner {
  background-color: rgba(0, 16, 62, 0.5);
  width: 100%;
  height: 100%;
  z-index: 10000012;
  display: flex;
  position: fixed;
  flex-direction: column;
  justify-content: center;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  top: 0;
  left: 0;
}

.download-spinner .contente-loading {
  width: 50%;
  background: #fff;
  text-align: center;
  padding: 30px;
  box-sizing: border-box;
}

.download-spinner .contente-loading {
  width: 50%;
  background: #fff;
  text-align: center;
  padding: 30px;
  box-sizing: border-box;
}

.texts h3 {
  color: #121f34 !important;
  margin: 15px 0 !important;
}

.texts h4 {
  color: #121f34 !important;
  margin: 0 !important;
  font-size: 16px !important;
}

/* ====== SPINNER ====== */
.spinner {
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;
}

.spinner div {
  transform-origin: 40px 40px;
  animation: spinner-animation 1.2s linear infinite;
}

.spinner div:after {
  content: " ";
  display: block;
  position: absolute;
  top: 3px;
  left: 35px;
  width: 10px;
  height: 20px;
  border-radius: 20%;
  background-color: rgba(16, 174, 239, 1);
}

.spinner div:nth-child(1) {
  transform: rotate(0deg);
  animation-delay: -1.1s;
}

.spinner div:nth-child(2) {
  transform: rotate(45deg);
  animation-delay: -1s;
}

.spinner div:nth-child(3) {
  transform: rotate(90deg);
  animation-delay: -0.9s;
}

.spinner div:nth-child(4) {
  transform: rotate(135deg);
  animation-delay: -0.8s;
}

.spinner div:nth-child(5) {
  transform: rotate(180deg);
  animation-delay: -0.7s;
}

.spinner div:nth-child(6) {
  transform: rotate(225deg);
  animation-delay: -0.6s;
}

.spinner div:nth-child(7) {
  transform: rotate(270deg);
  animation-delay: -0.5s;
}

.spinner div:nth-child(8) {
  transform: rotate(315deg);
  animation-delay: -0.4s;
}

.center-content {
  margin: 0 auto;
}

/* DESK */
@media (max-width: 1334px) {
  .contenido {
    margin: var(--margin-cont);
  }

  .wrapper {
    width: 300px;
    margin-left: auto;
    margin-right: auto;
    margin-top: 20vh;
    text-align: center;
    font-size: 1.5rem;
  }
}

/* TABLET */
@media (max-width: 1025px) and (min-width: 768px) {
  .caja-l {
    display: none;
  }

  .continuar {
    background-color: var(--p-blue);
    width: 227px !important;
    height: 60px;
    color: var(--p-white);
    outline: none;
    height: 50px;
    font: var(--font-p-20);
    border: none;
    border-radius: 5px;
  }

  .m-bot {
    display: none !important;
  }

  .contenido {
    margin: var(--margin-cont);
    max-width: 900px;
  }
}

/* MOBILE */
@media (max-width: 450px) {
  .caja-l {
    display: none;
  }

  .mat-error {
    margin-top: 24px;
  }

  .btn-cam-rag {
    display: none !important;
  }

  .m-bot {
    display: block !important;
    background-color: var(--p-blue);
    width: 343px !important;
    height: 60px;
    color: var(--p-white) !important;
    outline: none;
    height: 50px;
    font: var(--font-p-20);
    border: none;
    border-radius: 5px;
  }

  app-camera>div>div>webcam>div>video {
    width: 100% !important;
    height: 250px !important;
  }

}

@media screen and (min-width: 640px) {
  .incidentes {
    max-width: 235px;
    width: 15vw;
  }

  .incidentes div {
    background: transparent !important;
  }

  .incidentes div .complemento {
    text-align: center !important;
    padding-left: 0;
    padding-top: 0.25rem;
    border-left: 0;
    border-top: 1px solid var(--bs-cyan);
    /* left: -20px; */
    bottom: 0;
    transform: translate(3vw, -20px);
  }

  #atlwdg-trigger {
    left: 40px;
    bottom: 0;
    font-size: 12px;
    transform: translate(0, -50px);
  }
}

@keyframes spinner-animation {
  0% {
    opacity: 1;
  }

  100% {
    opacity: 0;
  }
}

@keyframes crecer-vertical {
  0% {
    -webkit-transform: scaleY(0);
    transform: scaleY(0);
    -webkit-transform-origin: 100% 0%;
    transform-origin: 100% 0%;
    opacity: 1;
  }

  100% {
    -webkit-transform: scaleY(1);
    transform: scaleY(1);
    -webkit-transform-origin: 100% 0%;
    transform-origin: 100% 0%;
    opacity: 1;
  }
}

@keyframes decrecer-vertical {
  0% {
    transform: scaleY(1);
    opacity: 1;
  }

  100% {
    transform: scaleY(0);
    opacity: 1;
  }
}

@keyframes agrandar {
  0% {
    transform: scale(1);
  }

  100% {
    transform: scale(1.2);
  }
}

/* Animación del error */
@keyframes agitar {

  0%,
  100% {
    -webkit-transform: translateX(0);
    transform: translateX(0)
  }

  10%,
  30%,
  50%,
  70% {
    -webkit-transform: translateX(-10px);
    transform: translateX(-10px)
  }

  20%,
  40%,
  60% {
    -webkit-transform: translateX(10px);
    transform: translateX(10px)
  }

  80% {
    -webkit-transform: translateX(8px);
    transform: translateX(8px)
  }

  90% {
    -webkit-transform: translateX(-8px);
    transform: translateX(-8px)
  }
}

@keyframes pulsacion {
  0% {
    transform: scale(1);
  }

  50% {
    transform: scale(1.1);
  }

  100% {
    transform: scale(1);
  }
}

/* Animación del spinner */
@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

@keyframes mover {
  0% {
    transform: translateX(0%);
    opacity: 1;
  }

  10% {
    transform: translateX(-20%);
    opacity: 0.8;
  }

  20% {
    transform: translateX(-40%);
    opacity: 0.6;
  }

  30% {
    transform: translateX(-60%);
    opacity: 0.4;
  }

  40% {
    transform: translateX(-80%);
    opacity: 0.2;
  }

  50% {
    transform: translateX(-100%);
    opacity: 0;
  }

  100% {
    transform: translateX(0%);
    opacity: 1;
  }
}

@keyframes reaparecer {
  0% {
    transform: translateX(0%);
    opacity: 1;
  }

  10% {
    transform: translateX(20%);
    opacity: 0.8;
  }

  20% {
    transform: translateX(40%);
    opacity: 0.6;
  }

  30% {
    transform: translateX(60%);
    opacity: 0.4;
  }

  40% {
    transform: translateX(80%);
    opacity: 0.2;
  }

  50% {
    transform: translateX(100%);
    opacity: 0;
  }

  60% {
    transform: translateX(80%);
    opacity: 1;
  }

  70% {
    transform: translateX(60%);
    opacity: 1;
  }

  80% {
    transform: translateX(40%);
    opacity: 1;
  }

  90% {
    transform: translateX(20%);
    opacity: 1;
  }

  100% {
    transform: translateX(0%);
    opacity: 1;
  }
}

@keyframes slit-in-vertical {
  0% {
    transform: rotateY(0);
  }

  25% {
    transform: rotateY(90);
  }

  50% {
    transform: translateZ(-800px) rotateY(90deg);
    opacity: 0;
  }

  75% {
    transform: translateZ(-160px) rotateY(87deg);
    opacity: 1;
  }

  100% {
    transform: translateZ(0) rotateY(0);
  }
}

@keyframes swing-in-bottom-fwd {
  0% {
    transform: rotateX(100deg);
    transform-origin: bottom;
    box-shadow: 0 2px 60px rgba(0, 0, 0, 1);
    opacity: 0;
  }

  100% {
    transform: rotateX(0);
    transform-origin: bottom;
    box-shadow: 0 2px 6px rgba(0, 0, 0, 0.2);
    opacity: 1;
  }
}

@keyframes spinner-nuevo {
  12% {
    transform: rotate(45deg);
  }

  25% {
    transform: rotate(90deg);
  }

  37% {
    transform: rotate(135deg);
  }

  50% {
    transform: rotate(180deg);
  }

  62% {
    transform: rotate(225deg);
  }

  75% {
    transform: rotate(270deg);
  }

  87% {
    transform: rotate(315deg);
  }

  100% {
    transform: rotate(360deg);
  }
}
